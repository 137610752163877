.App {
  text-align: center;
  line-height: 1.4;
}

iframe {
  margin-top: 50px;
  margin-bottom: 50px;
}

.MuiCardMedia-root {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

a,
a:visited {
  text-decoration: none;
  transition: color 0.35s, font-size 1s;
}

.header-links,
.header-links:visited {
  text-decoration: none;
  transition: color 0.35s, font-size 1s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiTypography-root img {
  max-width: 340px;
  margin: '0 auto';
}

/*

Media Queries

*/

@media screen and (max-width: 600px) {
  .header-links {
    display: none;
  }

  .MuiTypography-h4 {
    font-size: 24px;
  }
}

@media screen and (min-width: 900px) {
  iframe {
    height: 394px;
    width: 700px;
    margin: '0 auto';
  }
}

@media screen and (min-width: 200px) {
  iframe {
    width: 333px;
    margin: '0 auto';
  }
}

@media screen and (min-width: 900px) {
  iframe {
    height: 394px;
    width: 700px;
    margin: '0 auto';
  }

  .MuiTypography-root img {
    max-width: 700px;
    margin: '0 auto';
  }
}

@media screen and (min-width: 1500px) {
  iframe {
    height: 507px;
    width: 870px;
    margin: '0 auto';
  }

  .MuiTypography-root img {
    max-width: 870px;
    margin: '0 auto';
  }
}